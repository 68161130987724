import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  isDevMode,
  OnInit,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { combineLatest } from "rxjs";
import { ProcessFacade } from "@cg/olb/state";
import { BaseDirective, ComponentResolverService } from "@cg/olb/tiles";
import { slideUpAnimation, slideUpAnimationDone, STATE_HIDE, STATE_NO_ANIMATION, STATE_SHOW } from "@cg/animation";
import { ProcessToHtmlIdPipe } from "@cg/olb/shared";
import { ProcessId, ProcessMetadata } from "@cg/shared";
import { EditOverlayComponent } from "../../../components/edit-overlay/edit-overlay.component";

@Component({
  selector: "cg-olb-step",
  templateUrl: "./olb-step.component.html",
  animations: [slideUpAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [EditOverlayComponent, ProcessToHtmlIdPipe]
})
export class OlbStepComponent implements OnInit {
  @Input()
  public processMetadata: ProcessMetadata | undefined;

  @ViewChild("render", { static: true, read: ViewContainerRef })
  public renderComponentSlot: ViewContainerRef;

  @ViewChild("prefix", { static: true, read: ViewContainerRef })
  public prefixComponentSlot: ViewContainerRef;

  public editOverlayVisible = false;

  public hasPrefixComponent = false;
  public slideUpState = STATE_HIDE;
  public slideUpAnimationDone = slideUpAnimationDone;

  public constructor(
    private readonly processFacade: ProcessFacade,
    private readonly componentResolverService: ComponentResolverService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  public async ngOnInit() {
    await this.resolvePrefixComponent();
    await this.resolveStepComponent();

    if (this.processMetadata) {
      const processMetaData$ = this.processFacade.getProcessMetaDataForProcessId(this.processMetadata.id);

      const currentProcessId$ = this.processFacade.currentProcessId$;

      combineLatest([processMetaData$, currentProcessId$]).subscribe(
        ([data, processId]: [ProcessMetadata, ProcessId]) => {
          this.editOverlayVisible = data && data.valid && processId !== this.processMetadata.id;
          this.cdr.markForCheck();
        }
      );
    }
  }

  private async resolvePrefixComponent() {
    const component = await this.componentResolverService.getPrefixComponent(this.processMetadata.id);

    if (component) {
      this.prefixComponentSlot.createComponent(component);
      this.hasPrefixComponent = true;
      setTimeout(() => {
        this.slideUpState = STATE_SHOW;
        this.cdr.markForCheck();
      }, 1500);
    } else {
      this.slideUpState = STATE_NO_ANIMATION;
    }
  }

  private async resolveStepComponent() {
    const component = await this.componentResolverService.getComponent(this.processMetadata.id);

    if (component) {
      const ref = this.renderComponentSlot.createComponent(component);
      (ref.instance as BaseDirective<unknown>).processMetadata = this.processMetadata;
    } else {
      if (isDevMode()) {
        console.error(`No component found for processId: ${this.processMetadata.id}`);
      }
    }
  }
}
