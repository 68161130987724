import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { IconComponent } from "@cg/core/ui";
import { arrowsIcon, successIcon } from "@cg/icon";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { BaseButtonComponent, OverlayService } from "@cg/shared";

@Component({
  selector: "cg-save-success-dialog-content",
  templateUrl: "./save-success-dialog-content.component.html",
  styleUrls: ["./save-success-dialog-content.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, IconComponent, OlbHeadlineComponent, BaseButtonComponent]
})
export class SaveSuccessDialogContentComponent {
  public readonly successIcon = successIcon;
  public readonly arrowsIcon: Icon = arrowsIcon;

  public constructor(
    private overlayService: OverlayService,
    private processFacade: ProcessFacade
  ) {}

  public onButtonClick(): void {
    this.processFacade.setUrlToNavigateAfterExit("/");
    this.processFacade.exitOlb();
  }

  public onClose() {
    this.overlayService.close();
  }
}
