import { animate, style, transition, trigger } from "@angular/animations";
import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Observable } from "rxjs";
import { ProcessFacade } from "@cg/olb/state";
import { closeIcon } from "@cg/icon";
import { CloseIconComponent, DialogComponent, ProgressbarComponent } from "@cg/shared";
import { SaveDialogContentComponent } from "../save-dialog-content/save-dialog-content.component";
import { SaveSuccessDialogContentComponent } from "../save-success-dialog-content/save-success-dialog-content.component";

@Component({
  selector: "cg-save-dialog",
  templateUrl: "./save-dialog.component.html",
  styleUrls: ["./save-dialog.component.scss"],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [style({ opacity: 0 }), animate("400ms ease-in", style({ opacity: 1 }))]),
      transition(":leave", [animate("400ms ease-in", style({ opacity: 0 }))])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    DialogComponent,
    ProgressbarComponent,
    SaveDialogContentComponent,
    SaveSuccessDialogContentComponent,
    CloseIconComponent
  ]
})
export class SaveDialogComponent {
  public saved = false;
  public closeIcon = closeIcon;

  public handleSaved() {
    this.saved = true;
  }

  public readonly processPercentage$: Observable<number>;

  public constructor(private readonly processFacade: ProcessFacade) {
    this.processPercentage$ = this.processFacade.getProgressPercentage();
  }
}
