/* eslint-disable sonarjs/no-duplicate-string */
import type { ProcessFlow } from "@cg/olb/shared";
import { processFlow } from "./process-flow.model";

export const processFlowNoInsuranceTile: ProcessFlow = {
  ...processFlow,
  "damage-location": {
    ...processFlow["damage-location"],
    exitNodes: {
      ...processFlow["damage-location"].exitNodes,
      noGdvForReplace: "license-plate/no-gdv-for-replace",
      gdvAndFinForReplace: "license-plate/gdv-and-fin-for-replace",
      directResume: "appointment",
      directResumeOtherLocationDamaged: "damage-size"
    }
  },
  "damage-type": {
    ...processFlow["damage-type"],
    exitNodes: {
      ...processFlow["damage-type"].exitNodes,
      noGdvForReplace: "license-plate/no-gdv-for-replace",
      gdvAndFinForReplace: "license-plate/gdv-and-fin-for-replace",
      directResume: "appointment"
    }
  },
  "damage-size": {
    ...processFlow["damage-size"],
    exitNodes: {
      ...processFlow["damage-size"].exitNodes,
      smallSizeDamage: "license-plate/no-date-no-gdv",
      noGdvForReplace: "license-plate/no-gdv-for-replace",
      gdvAndFinForReplace: "license-plate/gdv-and-fin-for-replace",
      directResume: "appointment",
      directResumeSmallSizeDamage: "appointment"
    }
  },
  "damage-location-multiple-chips": {
    ...processFlow["damage-location-multiple-chips"],
    exitNodes: {
      ...processFlow["damage-location-multiple-chips"].exitNodes,
      fieldOfViewOrEdgeDamaged: "license-plate/gdv-and-fin-for-replace",
      otherLocationDamaged: "license-plate/no-date-no-gdv",
      gdvAndFinForReplace: "license-plate/gdv-and-fin-for-replace",
      noGdvForReplace: "license-plate/no-gdv-for-replace",
      directResume: "appointment",
      directResumeOtherLocationDamaged: "appointment"
    }
  },
  "damage-size-multiple-chips": {
    ...processFlow["damage-size-multiple-chips"],
    exitNodes: {
      ...processFlow["damage-size-multiple-chips"].exitNodes,
      noGdvForReplace: "license-plate/no-gdv-for-replace",
      gdvAndFinForReplace: "license-plate/gdv-and-fin-for-replace",
      directResume: "appointment",
      directResumeSmallSizeDamage: "damage-location-multiple-chips"
    }
  },
  "damage-chip-count": {
    ...processFlow["damage-chip-count"],
    exitNodes: {
      ...processFlow["damage-chip-count"].exitNodes,
      twoOrThreeDamageChipCount: "damage-size-multiple-chips",
      moreThanThreeDamageChipCount: "license-plate/gdv-and-fin-for-replace",
      directResume: "appointment"
    }
  },
  "license-plate/no-gdv-for-replace": {
    ...processFlow["license-plate/no-gdv-for-replace"],
    passthroughId: "damage-assessment-result"
  },
  "license-plate/gdv-and-fin-for-replace": {
    ...processFlow["license-plate/gdv-and-fin-for-replace"],
    passthroughId: "damage-assessment-result"
  },
  "license-plate/no-date-no-gdv": {
    ...processFlow["license-plate/no-date-no-gdv"],
    passthroughId: "damage-assessment-result"
  }
};
