@if (visible) {
  <div
    class="edit-overlay-wrapper absolute left-0 top-0 z-40 flex h-full w-full items-center justify-center rounded-card bg-cg-gray-light-70/90"
    [@inOutAnimation]
    [attr.data-cy]="processId"
  >
    <cg-icon
      class="[&>mat-icon]:block [&>mat-icon]:!h-[6.375rem] [&>mat-icon]:!w-[6.375rem] [&>mat-icon]:t:!h-40 [&>mat-icon]:t:!w-40"
      [content]="displayIcon"
    ></cg-icon>
    @if (isEditPossible) {
      <cg-olb-edit
        class="absolute bottom-10 right-4 t:bottom-6 t:right-6 d:bottom-10 d:right-10 fhd:right-15"
        (click)="hideOverlay()"
      >
      </cg-olb-edit>
    }
  </div>
}
