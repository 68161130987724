import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostListener,
  inject,
  Inject,
  Input,
  OnInit
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ProcessFacade, YextFacade } from "@cg/olb/state";
import { IS_BROWSER_PLATFORM } from "@cg/core/utils";
import { FooterComponent } from "@cg/footer";
import { HeaderComponent } from "@cg/header";
import { MetaDataService } from "@cg/html-header";
import { OlbBaseComponent, OlbFlowComponent } from "@cg/olb/core";
import { InitService, ScrollService } from "@cg/olb/shared";
import { ProcessId } from "@cg/shared";

@Component({
  selector: "cg-olb-b2c",
  templateUrl: "./olb-b2c.component.html",
  styleUrls: ["./olb-b2c.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeaderComponent, FooterComponent, OlbFlowComponent]
})
export class OlbB2cComponent extends OlbBaseComponent implements OnInit {
  @Input()
  public yextInfo: string;

  public destroyRef = inject(DestroyRef);
  private processId: ProcessId;

  public constructor(
    protected readonly process: ProcessFacade,
    protected readonly scrollService: ScrollService,
    protected readonly metaDataService: MetaDataService,
    protected readonly initService: InitService,
    @Inject(IS_BROWSER_PLATFORM) public readonly isBrowser: boolean,
    protected readonly yextFacade: YextFacade
  ) {
    super(process, scrollService, metaDataService, initService, isBrowser);
  }

  @HostListener("window:beforeunload", ["$event"])
  public beforeunloadHandler(event: BeforeUnloadEvent) {
    if (this.processId === "appointment-confirmation" || this.processId === "opportunity-funnel-success") {
      return;
    }

    event.preventDefault();
    event.returnValue = "";
  }

  public ngOnInit() {
    if (this.yextInfo) {
      this.yextFacade.setInfos(this.yextInfo);
    }

    this.metaDataService.addOrUpdateTag("robots", "noindex,follow");

    if (!this.isBrowser) {
      return;
    }

    super.ngOnInit();

    this.process.currentProcessId$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((processId: ProcessId) => {
      this.processId = processId;
    });
  }
}
