import { animate, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { map } from "rxjs/operators";
import { ProcessFacade } from "@cg/olb/state";
import { TrackingService } from "@cg/analytics";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { IconComponent } from "@cg/core/ui";
import { errorIcon, successIcon } from "@cg/icon";
import { ProcessId, ProcessMetadata } from "@cg/shared";
import { OlbEditComponent } from "../olb-edit/olb-edit.component";

@Component({
  selector: "cg-edit-overlay",
  templateUrl: "./edit-overlay.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [style({ opacity: 0 }), animate("400ms ease-in", style({ opacity: 1 }))]),
      transition(":leave", [animate("400ms ease-in", style({ opacity: 0 }))])
    ])
  ],
  standalone: true,
  imports: [IconComponent, OlbEditComponent]
})
export class EditOverlayComponent implements OnInit {
  public visible = false;
  @Input() public processId: ProcessId;

  public isEditPossible = true;

  public displayIcon: Icon = successIcon;
  public errorIcon: Icon = errorIcon;

  public constructor(
    private processFacade: ProcessFacade,
    private cdr: ChangeDetectorRef,
    private trackingService: TrackingService
  ) {}

  public ngOnInit(): void {
    this.processFacade.processMetaData$.subscribe((processMetaData: ProcessMetadata[]) => {
      this.visible = processMetaData[processMetaData.length - 1].id !== this.processId;
      this.cdr.markForCheck();
    });

    this.processFacade.technicalError$
      .pipe(map((technicalError: string) => !!technicalError))
      .subscribe((hasTechnicalError: boolean) => {
        this.isEditPossible = !hasTechnicalError;

        if (hasTechnicalError) {
          this.displayIcon = this.errorIcon;
        } else {
          this.displayIcon = successIcon;
        }

        this.cdr.markForCheck();
      });
  }

  public hideOverlay() {
    this.trackingService.trackEvent({
      eventAction: "adjust",
      eventLabel: this.trackingService.returnProcessId(this.processId)
    });

    this.processFacade.closeEditOverlay(this.processId);
  }
}
