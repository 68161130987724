import { NewAppointmentComponent } from "@cg/olb/tiles";
import type { AbTestComponentResolverMapping } from "@cg/olb/shared";
import { OptimizelyExperiment } from "@cg/core/enums";
// import { OptimizelyExperiment } from "@cg/core/enums";
// import { InsuranceTypeVariantComponent } from "@cg/olb/tiles";

export const abTestProcessFlow: AbTestComponentResolverMapping = {
  // Example entry to show how to use the resolver mapping.
  // "insurance-type": {
  //   [OptimizelyExperiment.INSURANCE_TYPE_AB206]: {
  //     variations: [
  //       {
  //         variationId: "22855132146", // UAT
  //         component: InsuranceTypeVariantComponent
  //       },
  //       {
  //         variationId: "22840601949", // PROD
  //         component: InsuranceTypeVariantComponent
  //       }
  //     ]
  //   }
  // }
  appointment: {
    [OptimizelyExperiment.NEW_APPOINTMENT_TILE]: {
      variations: [
        {
          variationId: "24898400670", // UAT
          component: NewAppointmentComponent
        },
        {
          variationId: "24880860287", // PROD
          component: NewAppointmentComponent
        }
      ]
    },
    [OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP]: {
      variations: [
        {
          variationId: "28180250089", // UAT
          component: NewAppointmentComponent
        },
        {
          variationId: "28189890080", // PROD
          component: NewAppointmentComponent
        }
      ]
    }
  }
};
