<div class="wrapper">
  <cg-icon class="icon h-[72px] w-[72px]" [content]="successIcon"></cg-icon>

  <cg-olb-headline
    class="title"
    [title]="'saveSuccessDialogContent.title' | transloco"
    [subHeadline]="'saveSuccessDialogContent.subtitle' | transloco"
  ></cg-olb-headline>

  <div class="line"></div>

  <cg-olb-headline class="info-title" [title]="'saveSuccessDialogContent.infoTitle' | transloco"></cg-olb-headline>

  <button
    class="part-[icon-text-wrapper]:px-4"
    cg-base-button
    [btnText]="'saveSuccessDialogContent.moreInfoBtnTitle' | transloco"
    [icon]="arrowsIcon"
    (click)="onButtonClick()"
  ></button>
</div>
