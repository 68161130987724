import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { combineLatest } from "rxjs";
import { filter, first } from "rxjs/operators";
import { ContactDataFacade, CustomerCaseFacade, DamageFacade, ProcessFacade } from "@cg/olb/state";
import * as CgValidators from "@cg/validators";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import { arrowsIcon } from "@cg/icon";
import { LpnInputComponent, LpnInputForm } from "@cg/lpn-input";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import {
  BaseButtonComponent,
  CheckboxComponent,
  ComponentOverarchingChangeDetectionService,
  CustomerService,
  InputType,
  Lpn,
  TextInputComponent
} from "@cg/shared";
import { SaveDialogForm } from "../../../interfaces/save-dialog-form.interface";
import { checkboxContent } from "../../../models/checkbox.model";
import { inputFields } from "../../../models/input-fields.model";
@Component({
  selector: "cg-save-dialog-content",
  templateUrl: "./save-dialog-content.component.html",
  styleUrls: ["./save-dialog-content.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    ReactiveFormsModule,
    OlbHeadlineComponent,
    LpnInputComponent,
    TextInputComponent,
    CheckboxComponent,
    BaseButtonComponent
  ]
})
export class SaveDialogContentComponent implements OnInit {
  @Output()
  public readonly save = new EventEmitter<void>();

  public readonly form: FormGroup<AddFormControls<SaveDialogForm>>;

  public readonly inputFields = inputFields;
  public readonly checkboxContent = checkboxContent;
  public readonly ctaButtonIcon = arrowsIcon;

  protected readonly InputType = InputType;

  // eslint-disable-next-line max-params
  public constructor(
    private readonly customerService: CustomerService,
    private readonly customerCaseFacade: CustomerCaseFacade,
    private readonly damageFacade: DamageFacade,
    private readonly contactDataFacade: ContactDataFacade,
    private readonly processFacade: ProcessFacade,
    private readonly changeDetection: ChangeDetectorRef,
    private readonly trackingService: TrackingService,
    private readonly changeDetectionService: ComponentOverarchingChangeDetectionService
  ) {
    this.form = new FormGroup<AddFormControls<SaveDialogForm>>({
      lpn: new FormControl<LpnInputForm>(null, Validators.required),
      email: new FormControl<string>(null, CgValidators.emailValidators(true)),
      accept: new FormControl<boolean>(null, Validators.requiredTrue)
    });
  }

  public ngOnInit(): void {
    this.setFormData();
  }

  public onSend() {
    this.form.markAllAsTouched();
    this.changeDetectionService.changeDetectionRequest$.next();

    if (!this.form.valid) {
      this.trackingService.trackEvent({
        eventAction: "my-carglass",
        eventLabel: "save-progress-fail"
      });
      return;
    }

    const formValue = this.form.value;

    this.contactDataFacade.setEmail(formValue.email);
    const lpn: Lpn = {
      region: formValue.lpn.region,
      letters: formValue.lpn.letters,
      numbers: formValue.lpn.numbers
    };
    this.damageFacade.setLpn(lpn);
    this.processFacade.saveRestoreState(formValue.accept);

    this.save.emit();

    this.trackingService.trackEvent({
      eventAction: "my-carglass",
      eventLabel: "save-progress-success"
    });
  }

  private setFormData() {
    combineLatest([this.customerCaseFacade.customerCaseId$, this.damageFacade.lpn$, this.contactDataFacade.email$])
      .pipe(
        filter(
          ([customerCaseId, _lpn, _email]: [string | null | undefined, Lpn | null, string | null]) => !!customerCaseId
        ),
        first()
      )
      .subscribe(([customerCaseId, lpn, email]: [string | null | undefined, Lpn | null, string | null]) => {
        if (lpn !== null) {
          this.form.controls.lpn.setValue({ ...lpn });
        }

        this.form.controls.email.setValue(email);

        if (customerCaseId) {
          this.form.controls.email.addAsyncValidators(
            CgValidators.emailMxValidator(this.getEmailMxValidatorFn(customerCaseId), 750)
          );
        } else {
          throw new Error("[SaveDialogContentComponent] CustomerCaseId is undefined");
        }

        this.form.updateValueAndValidity();
        this.changeDetection.markForCheck();
      });
  }

  private getEmailMxValidatorFn(customerCaseId: string) {
    return (value: string) => this.customerService.validateEmail(customerCaseId, value);
  }
}
