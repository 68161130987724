<cg-dialog [showCloseButton]="false" [scrollable]="false" [headerElement]="headerElement">
  <ng-template #headerElement>
    <div class="mx-auto box-border flex grow items-center justify-between gap-4 pr-10 d:pr-4">
      <cg-progressbar class="grow" [progress]="processPercentage$ | async"></cg-progressbar>
      <cg-close-icon></cg-close-icon>
    </div>
  </ng-template>
  <div
    class="max-h-dialog-content-xs overflow-y-auto m:max-h-dialog-content-m t:max-h-dialog-content-t d:max-h-dialog-content-d"
  >
    @if (saved === false) {
      <cg-save-dialog-content class="block" (save)="handleSaved()"></cg-save-dialog-content>
    }
  </div>
  @if (saved) {
    <cg-save-success-dialog-content [@inOutAnimation]></cg-save-success-dialog-content>
  }
</cg-dialog>
