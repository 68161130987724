import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { penGrayIcon } from "@cg/icon";
import { BaseButtonComponent } from "@cg/shared";

@Component({
  selector: "cg-olb-edit",
  templateUrl: "./olb-edit.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, BaseButtonComponent]
})
export class OlbEditComponent extends BaseButtonComponent implements OnInit {
  public ngOnInit() {
    this.btnText = "olbEdit.text";
    this.icon = penGrayIcon;
  }
}
