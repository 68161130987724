<div class="relative box-border flex w-full shrink-0 flex-col flex-nowrap">
  <div class="flex h-full w-full flex-col flex-nowrap" [id]="processMetadata.id | processToHtmlId">
    <div class="w-full flex-auto" [class.is-editing]="editOverlayVisible">
      <cg-edit-overlay [processId]="processMetadata.id"></cg-edit-overlay>
      <!-- used to load real component dynamically -->
      <ng-template #prefix></ng-template>

      <div [@slideUp]="slideUpState" (@slideUp.done)="slideUpAnimationDone($event)">
        <ng-template #render></ng-template>
      </div>
    </div>
  </div>
</div>
