import { provideState } from "@ngrx/store";
import { provideOlbEffects } from "@cg/olb/effects";
import { provideOlbState, YEXT_FEATURE_KEY, yextReducer } from "@cg/olb/state";
import { corePrefixComponentMapping, CoreProcessComponentTileMapping } from "@cg/olb/tiles";
import { provideOlbCore } from "@cg/olb/core";
import { processFlow } from "./models/process-flow.model";
import { abTestProcessFlow } from "./models/process-flow-ab-tests.model";

export function provideOlbB2cCore() {
  return [
    provideOlbCore(processFlow, CoreProcessComponentTileMapping, corePrefixComponentMapping, abTestProcessFlow),
    provideOlbState(),
    provideOlbEffects(),
    provideState(YEXT_FEATURE_KEY, yextReducer)
  ];
}
