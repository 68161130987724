import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "@cg/core/api";

@Injectable({
  providedIn: "root"
})
export class BiProcessorService {
  public constructor(private apiService: ApiService) {}

  public sendABVariations(message: string, customerCaseId: string): Observable<unknown> {
    return this.apiService.post(
      "biProcessor",
      `/optimizely/customercase/${customerCaseId}/${message.split("::").shift()}`,
      message
    );
  }
}
