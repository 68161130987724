import type { CheckboxInput } from "@cg/shared";

export const checkboxContent: CheckboxInput = {
  id: "accept-checkbox",
  controlName: "accept",
  text: "saveDialogContent.checkboxText"
};

export const checkboxExitSaveContent: CheckboxInput = {
  id: "accept-checkbox",
  controlName: "accept",
  text: "saveDialogContent.exitAb.checkboxText"
};
