import type { TextInput } from "@cg/shared";

export const inputFields: { emailInput: TextInput } = {
  emailInput: {
    id: "customer-contact-email",
    controlName: "email",
    placeholder: "saveDialogContent.inputs.email.placeholder",
    errorMessage: "saveDialogContent.inputs.email.errorMessage"
  }
};
